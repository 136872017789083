<template>
  <div class="page-box" id='good-list'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="商品名称" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="供应商" prop="supplyShopId">
          <a-spin class="custom-spin" :spinning="listPartnerLoading" size="small">
            <a-select style='min-width:200px' v-model="queryRef.supplyShopId" placeholder="请选择" allowClear>
              <a-select-option v-for="d in listPartner" :key="d.shopId">
                <span class="custom-select-option">{{d.name}}</span>
              </a-select-option>
            </a-select>
          </a-spin>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.productId" :loading="loadingRef" @change="changeTablePage" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
      <template #goodsName="record">
        <router-link tag="div" class="goods-info goods" :to="`/goods/goods_detail/${record.productId}`">
          <base-img class="goods-img" :src="record.productImg" width="60" height="60" oss_style="60_60" />
          <a-tooltip :title="record.name">
            <p class="goods-name el2">{{record.name}}</p>
          </a-tooltip>
        </router-link>
      </template>
      <template #supplyPrice="record">
        <p>{{record.supplyPrice}}</p>
      </template>
      <template #tradePrice="record">
        <p>{{record.tradePrice}}</p>
        <!-- <p v-show="record.tradePriceMarkup">加价率：{{record.tradePriceMarkup}}%</p> -->
      </template>
      <template #shopInfo="record">
        <div class="goods-info">
          <base-img class="goods-img" :src="record.shopLogo" width="60" height="60" oss_style="60_60" />
          <div>
            <p @click="goShopDetail(record.collectShopId)" class="goods-name shop-name el2">{{record.shopName}}</p>
            <span :style="{fontSize: '13px', color: shopAuthStateFormat(record.shopAuthState, 'color')}">{{shopAuthStateFormat(record.shopAuthState, 'text')}}</span>
          </div>
        </div>
      </template>
    </base-table>
    <p>已选{{selectedRowKeys.length}}件商品</p>
    <div class='btn-box'>
      <a-button type='primary' :loading='btnLoading' :disabled='!selectedRowKeys.length' @click='addPromoteProduct'>添加</a-button>
      <a-button type='info' @click='$closeCurrentView()'>取消</a-button>
    </div>
  </div>
</template>

<script>
import { shopAuthStates, productStates } from '@/utils/constants'
import { SHOPID } from '../../constants'
import { getSession } from '@/utils/session'
import { ref, onMounted, reactive, toRefs } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { marketing, common } from '@/api'
import NP from 'number-precision'
const columns = [
  {
    title: '商品名称',
    scopedSlots: {
      customRender: 'goodsName',
    },
  },
  {
    title: '供应商',
    scopedSlots: {
      customRender: 'shopInfo',
    },
  },
  {
    title: '成本价',
    scopedSlots: {
      customRender: 'supplyPrice',
    },
  },
  {
    title: '供货价',
    scopedSlots: {
      customRender: 'tradePrice',
    },
  },
  {
    title: '库存',
    dataIndex: 'stock',
  },
  {
    title: '销量',
    dataIndex: 'sales',
  },
  {
    title: '商品状态',
    dataIndex: 'state',
    customRender: (x) => {
      const matching = productStates.find((j) => j.value === +x)
      return matching ? matching.name : ''
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
  }
]
export default {
  name: 'PageGoodsList',
  setup (props, { root }) {
    const state = reactive({
      listPartner: [],
      selectedRowKeys: [],
      listPartnerLoading: false,
      btnLoading: false,
    })
    onMounted(async () => {
      getListPartner()
    })

    const formRef = ref(null)
    const queryRef = ref({
      name: '',
      supplyShopId: undefined,
    })
    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(
      marketing.getChooseProducts,
      queryRef,
      true,
      (res) =>
        res
          ? res.map((x) => ({
            ...x,
            shopAuthState: typeof x.shopAuthState === 'undefined' ? 0 : x.shopAuthState,
            tradePriceMarkup: x.tradePriceMarkup ? NP.times(x.tradePriceMarkup, 100) : '',
          }))
          : []
    )

    function handleSearch () {
      resetPage()
      refresh()
    }

    function handleReset () {
      formRef.value.resetFields()
      handleSearch()
    }

    function changeTablePage ({ current }) {
      setPage(current)
    }

    async function getListPartner () {
      state.listPartnerLoading = true
      const { data } = await common.getListPartner()
      state.listPartnerLoading = false
      state.listPartner = data || []
      if (
        root.$store.getters['head/getShopInfo'].name &&
        root.$store.getters['head/getShopInfo'].shopId
      ) {
        state.listPartner.push({
          name: root.$store.getters['head/getShopInfo'].name,
          shopId: root.$store.getters['head/getShopInfo'].shopId,
        })
      }
    }
    function onSelectChange (selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    function goShopDetail (id) {
      if (id === getSession(SHOPID)) {
        root.$router.push('/shop/info')
      } else {
        root.$router.push('/goods/shop_goods_list/' + id)
      }
    }
    function shopAuthStateFormat (state, type) {
      if (!state) return ''
      const matching = shopAuthStates.find((x) => x.value === +state)
      if (type === 'text') return matching ? matching.name : ''
      if (type === 'color') return matching ? matching.color : ''
    }
    async function addPromoteProduct () {
      state.btnLoading = true
      const { msg, code } = await marketing.addPromoteProduct(state.selectedRowKeys)
      state.btnLoading = false
      if (code === '00000') {
        root.$message.success('添加成功')
        state.selectedRowKeys = []
        handleReset()
      } else {
        root.$message.error(msg || '添加失败')
      }
    }
    return {
      ...toRefs(state),
      formRef,
      queryRef,
      columns,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleReset,
      changeTablePage,
      onSelectChange,
      goShopDetail,
      addPromoteProduct,
      shopAuthStateFormat
    }
  },
}
</script>

<style lang="less" scoped>
.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep .ant-btn {
    margin-right: 24px;
    &:last-child {
      margin: 0;
    }
  }
}
.goods-info {
  display: flex;
  align-items: center;
  &.goods {
    cursor: pointer;
  }
  .goods-img {
    flex: none;
    margin-right: 12px;
  }
  .goods-name {
    flex: 0 1 auto;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066ff;
    &.shop-name {
      color: #0066ff;
      cursor: pointer;
    }
  }
}
.table-operations ::v-deep .ant-btn {
  padding: 0 5px;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
.search-box {
  display: flex;
  align-items: center;
  margin: 12px 16px;
  justify-content: flex-end;
}
.product-modal ::v-deep .ant-modal-body {
  padding: 0 24px 12px;
}
.table-goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    width: 60px;
  }
  .goods-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.product-table ::v-deep td {
  padding: 4px 12px;
}
</style>
